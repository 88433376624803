import React from 'react'
import "../../../stylesheets/base/_catalogs.scss"
import Winleader from '../../../../static/downloadable/winleader.pdf'

function catalogs() {
    return (
        <div className='catalog'>

           <div className="catalogs-container">
           <h1>Rewards & Branded Apparel Catalogs</h1>

           <div className="btn_container">

               <a className="button" href="https://media.sanmarcanada.com/pdfs/catalogues/2022_Collection.zip" target="_blank">Sanmar</a>

               <a className="button" href="https://viewer.zoomcatalog.com/s-and-s-canada-2021" target="_blank">S&S</a>

               <a className="button" href={Winleader} download>Winleader</a>

            </div>
           </div>
            
        </div>
    )
}

export default catalogs
